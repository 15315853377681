<template lang="pug">
b-modal(
  no-fade
  no-close-on-esc
  no-close-on-backdrop
  ok-title="Login"
  cancel-disabled
  :visible="visible"
  lazy
  :centered="true"
  @shown="$refs.email.focus()")

  b-alert(:show="showLogoutInfo" dismissible variant='warning') {{logoutInfo}}

  b-form(autocomplete="off" @click="validated=true" )
    b-row.mt-0
      b-col(style="height:4.5em")
        b-form-input.bg-no-img(
          ref="email"
          :state="validEmail"
          type="email"
          v-model="email"
          placeholder="email@domain.tld"
          @focus="validated=true"
          @keyup.enter.native="attemptLogin" autocomplete="username")
        b-form-invalid-feedback
          span(v-if="emptyEmail" v-t="'feedback.invalid.loginRequired'")
          span(v-else v-t="'feedback.invalid.loginMustBeEmail'")
    b-row.mt-0
      b-col(style="height:4.5em")
        b-form-input.bg-no-img(
          :state="validPassword"
          type="password"
          v-model="password"
          @focus="validated=true"
          @keyup.enter.native="attemptLogin" autocomplete="current-password")
        b-form-invalid-feedback(v-t="'feedback.invalid.passwordRequired'")

    b-row.mt-0(v-show="attempts > 0 && !attemptWasSuccess")
      b-col
        b-alert(show variant="danger" v-t="'feedback.invalid.auth'" dismissible @dismissed="attemptWasSuccess")

  div(slot="modal-footer")
    b-button(variant="primary" sm right @click="attemptLogin" :disabled="!valid") Login

  div(slot="modal-header")
    h3 Login
</template>

<script>
import { mapActions } from 'vuex'
import { INIT } from '@/store/modules/auth/actions.type'
import { isEmpty } from 'lodash'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      validated: false,
      attemptWasSuccess: false,
      attempts: 0,

      email: '',
      password: ''
    }
  },
  computed: {
    credentials () {
      return { email: this.email, password: this.password }
    },
    emptyEmail () {
      return isEmpty(this.email)
    },
    validEmail () {
      return !this.emptyEmail && this.$refs.email.validity.valid
    },
    validPassword () {
      return !isEmpty(this.password)
    },
    valid () {
      return this.validEmail && this.validPassword
    },
    logoutReason () {
      return this.$route.params.logoutReason || this.$route.query.logoutReason || ''
    },
    showLogoutInfo () {
      return Boolean(this.logoutReason)
    },
    logoutInfo () {
      const reason = this.logoutReason
      return reason ? this.$t(`feedback.logout.${reason}`) : ''
    }
  },
  mounted () {
    this.attemptWasSuccess = false
    this.attempts = 0
  },
  methods: {
    async attemptLogin () {
      this.$log.debug('attemptLogin', { valid: this.valid })

      if (!this.valid) {
        return
      }

      try {
        this.attemptWasSuccess = await this.init(this.credentials)
      } catch (err) { // there is no point in letting this bubble up to the error-reporter when we are not auth'ed
        this.$log.error(this.$t('feedback.invalid.auth'))
      } finally {
        this.attempts = this.attempts + 1
        this.password = ''
      }
    },
    ...mapActions('auth', [INIT])
  }
}
</script>
