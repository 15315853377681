export default {
  watch: {
    isDirty (dirty) {
      dirty && this.$log.warn('dirty')
    }
  },
  beforeRouteLeave (to, from, next) {
    this.$log.debug('beforeRouteLeave')

    const wasAutoLoggedOut = to.name === 'logout' && !!to.params.reason

    if (!wasAutoLoggedOut && this.isDirty) {
      this.$root
        .confirm(this.$t('confirm.route-change/discard-unsaved'))
        .then((confirm) => next(confirm))
      return
    }

    next()
  },
  beforeRouteUpdate (to, from, next) {
    this.$log.debug('beforeRouteUpdate')

    if (!to.params.newState && this.isDirty) {
      this.$root
        .confirm(this.$t('confirm.route-change/discard-unsaved'))
        .then((confirm) => next(confirm))
      return
    }

    if (to.params.resetState) {
      this.$log.debug('beforeRouteUpdate:RESET_STATE')
      this.reset()
      if (to.params.newState) {
        this.set(to.params.newState)
      }
      this.resetData && this.resetData()
    }

    next()
  }
}
